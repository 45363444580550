import Link from 'next/link'

import { Layout } from '../../components/Layout'
import { Navbar2 } from '../../components/Navbar2'

export const posts = [
  {
    title:
      'Digital Drive: How ADA Compliance Boosts User Experience and Dealer Success',
    slug: 'digital-drive-how-ada-compliance-boosts-user-experience-and-dealer-success',
    description:
      'Discover the impact of ADA compliance on automotive digital success. Explore its role in boosting trust, conversions, and creating accessible digital showrooms.',
    date: 'Nov 20, 2023',
    datetime: '2023-11-20',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1700502954/driverseat/Frame_7.png',
    readingTime: '10 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1700502954/driverseat/Frame_7.png"
          alt="Girl in car using a laptop"
          width={1600}
          height={800}
        />
        <p>
          Welcome to the fast lane of the automotive industry, where the virtual
          showroom is as pivotal as the gleaming cars on the lot. In this age of
          digital acceleration, it's not just about presenting sleek vehicles
          online; it's about ensuring that the online experience is accessible
          to everyone.
          <br />
          <br />
          Join us on this ride as we explore the twists and turns of ADA
          (Americans with Disabilities Act) compliance and how it isn't just a
          checkbox—it's the fuel that powers user experience and dealership
          success.
        </p>
        <h2>Understanding ADA and ADA Compliance</h2>
        <h3>What is ADA?</h3>
        <p>
          The Americans with Disabilities Act (ADA) is a powerful legislation
          that was enacted to dismantle barriers for individuals with
          disabilities.
          <br />
          <br />
          It goes beyond being just a law; it represents a steadfast commitment
          to inclusivity that has continuously evolved over time, transforming
          the landscape of equal access. The ADA ensures that people with
          disabilities have the same rights and opportunities as everyone else,
          enabling them to fully participate in all aspects of society. It has
          significantly influenced and reshaped the way accessibility is
          addressed, not only legally but also ethically, promoting a more
          inclusive and equitable society for all.
        </p>
        <h2>The Significance of ADA Compliance for Dealerships</h2>
        <h3>Why ADA Compliance Matters in General</h3>
        <p>
          Sure, it's a legal necessity, but it's also a nod to ethics. ADA
          compliance goes beyond avoiding legal trouble; it's about creating a
          digital environment that welcomes everyone, regardless of their
          abilities.
        </p>
        <h3>Why ADA Compliance is Crucial for Dealership Websites</h3>
        <p>
          In a world where car shopping often begins with a click, the user
          experience reigns supreme. ADA compliance isn't just a feature—it's
          the secret sauce that can turn a website visit into a lifelong
          customer relationship.
        </p>
        <h2>How ADA Compliance Enhances User Experience</h2>
        <h3>Making Content Perceivable</h3>
        <p>
          Let's kick off our journey by making content pop. Alt text for images
          isn't just a description; it's a gateway for those with visual
          impairments to experience the visual allure of your inventory.
          Multimedia captions? They're the subtitles that bring your videos to
          life for everyone.
        </p>
        <h3>Making Content Operable</h3>
        <p>
          Now, let's talk about operability. Imagine cruising through a website
          using only a keyboard. That's the reality for some users, and ensuring
          keyboard accessibility and intuitive navigation structures is our way
          of paving a smooth digital road.
        </p>
        <h3>Making Content Understandable</h3>
        <p>
          Clear language and consistent design aren't just for aesthetics.
          They're the beacons that guide users through your website. From clear
          calls-to-action to a layout that feels like a familiar road,
          understanding is the key to engagement.
        </p>
        <h3>Making Content Robust</h3>
        <p>
          Now, let's talk about resilience. Your website needs to be robust,
          like a well-engineered car. Compatibility with different devices and
          rigorous testing practices ensure that your digital showroom is ready
          for any road conditions.
        </p>
        <h2>ADA Compliance Best Practices for Dealership Websites</h2>
        <h3>Accessible Images and Descriptions</h3>
        <p>
          A picture may be worth a thousand words, but an image without proper
          alt text is a missed opportunity. Let's delve into guidelines for
          writing alt text that captures the essence of your vehicles, making
          your website a visual haven for all users.
        </p>
        <h3>Multimedia Accessibility</h3>
        <p>
          Lights, camera, action! But wait, have you considered captions and
          transcripts? These aren't just afterthoughts; they're the red carpet
          that welcomes all users, ensuring your multimedia content is a
          blockbuster hit for everyone.
        </p>
        <h3>Navigational Enhancements</h3>
        <p>
          Picture your website as a well-marked road. Keyboard-friendly
          navigation and focus order ensure that all users can cruise through
          your digital landscape effortlessly. It's not just about reaching a
          destination; it's about enjoying the journey.
        </p>
        <h3>Color Contrast and Readability</h3>
        <p>
          Your website's color palette isn't just for aesthetics; it's a roadmap
          for readability. Let's explore how ensuring proper color contrast can
          make your content not just visually appealing, but accessible to users
          with visual impairments.
        </p>
        <h3>Form and Input Accessibility</h3>
        <p>
          Forms are the gateways to customer interaction. Designing them with
          assistive technologies in mind isn't just good practice; it's the key
          to unlocking a seamless and frustration-free experience for all users.
        </p>
        <h1>The Positive Impact on Dealer Success</h1>
        <h2>Building Trust and Reputation</h2>
        <h3>Attracting a Diverse Customer Base</h3>
        <p>
          In the vast landscape of the digital highway, inclusive marketing
          practices set the stage for a diverse clientele. Imagine your digital
          billboard reaching individuals of all abilities, creating a welcoming
          space for everyone.
        </p>
        <h3>Fostering a Positive Brand Image</h3>
        <p>
          Your brand isn't just a logo; it's an ethos. Demonstrating a
          commitment to accessibility isn't just about compliance; it's about
          creating a positive brand image. It's about telling the world that
          your dealership values every potential customer.
        </p>
        <h2>Increasing Conversion Rates</h2>
        <h3>Improving the User Journey for All Visitors</h3>
        <p>
          Now, let's talk conversions. An enhanced user experience isn't just a
          luxury; it's a conversion catalyst. Reduced frustration and an
          inviting digital environment can turn a casual visitor into a loyal
          customer.
        </p>
        <h3>Reducing Bounce Rates Through Enhanced Accessibility</h3>
        <p>
          Bounce rates—every digital marketer's nemesis. By lowering barriers to
          entry through ADA compliance, you're not just keeping users on your
          site; you're inviting them to explore. It's the difference between a
          fleeting glance and a prolonged visit.
        </p>
        <h2>Legal Protection and Risk Mitigation</h2>
        <h3>Avoiding Legal Complications and Potential Lawsuits</h3>
        <p>
          Legal troubles are potholes on the business highway. Proactive ADA
          compliance isn't just about dodging these potholes; it's about
          ensuring a smooth legal ride. By adhering to regulations, you're not
          just compliant; you're future-proofing your dealership.
        </p>
        <h3>Demonstrating a Commitment to Compliance and Inclusivity</h3>
        <p>
          Corporate social responsibility isn't just a buzzword; it's the
          backbone of your dealership's reputation. ADA compliance isn't just a
          requirement; it's a demonstration of your commitment to inclusivity.
          It's about saying, "Our digital doors are open to everyone."
        </p>
        <br />
        <br />
        As we bring this journey to a close, the road ahead is clear. ADA
        compliance isn't a burden—it's an investment in the future. From the way
        you describe your vehicles to the ease with which users navigate your
        website, every detail matters. Dealerships aren't just selling cars;
        they're selling experiences, and ADA compliance is the key to ensuring
        that every user, regardless of ability, can be part of that experience.
        <br />
        <br />
        So, let's hit the accelerator on accessibility. Let's embrace ADA
        compliance not as a checklist but as a commitment to creating a digital
        showroom where everyone is not just welcome but feels at home. In the
        vast and competitive world of automotive digital spaces, let ADA
        compliance be the engine that propels your dealership to new heights—a
        place where every user feels the wind in their digital hair as they
        cruise through your online showroom.
        <br />
        <br />
        Buckle up, because the journey to a more accessible and successful
        digital future starts now. Driverseat is ADA compliant right out of the
        box, without the need for any third party plug ins.
      </>
    ),
  },
  {
    title:
      'Navigating the Future of Automotive Analytics: The ASC Specification for GA4',
    slug: 'navigating-the-future-of-automotive-analytics-the-asc-specification-for-ga4',
    description:
      'Discover the ASC Specification for GA4 and its benefits for automotive dealerships, including enhanced data quality and streamlined reporting.',
    date: 'Nov 6, 2023',
    datetime: '2023-11-06',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1672782423/driverseat/ASC_blog.png',
    readingTime: '4 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1672782423/driverseat/ASC_blog.png"
          alt="Google Logo on a Building"
          width={1200}
          height={707}
        />
        <p>
          In the fast-paced world of automotive dealerships, understanding the
          behavior of your online audience and optimizing your digital marketing
          strategies is paramount. That's where the{' '}
          <Link href="https://automotivestandardscouncil.com">
            Automotive Standards Council
          </Link>{' '}
          (ASC) Specification for Google Analytics 4 (GA4) comes into play.
          <br />
          <br />
          At Driverseat, we are thrilled to introduce our support for a
          standardized framework for universal event tracking. This exciting
          development opens up new possibilities for dealerships, empowering
          them to harness data for growth and success.
        </p>
        <h2>What is the ASC and why does it matter?</h2>
        <p>
          The ASC, short for the Automotive Standards Council, represents a
          collaborative effort by industry experts with a shared mission: to
          establish universal standards for implementing GA4 within the
          automotive sector. This industry-led initiative is geared towards
          streamlining web analytics and reporting practices, ensuring a
          consistent and reliable approach to data utilization.
        </p>
        <h2>Unlocking the Potential of the ASC Specification for GA4</h2>
        <p>
          So, what exactly is the ASC Specification for GA4, and what benefits
          does it bring to your dealership?
          <br />
          <br />
          In simple terms, it's a guiding document that is set to revolutionize
          the way dealerships approach web analytics. This comprehensive guide
          offers standards and best practices for deploying GA4 in the
          automotive industry, streamlining the setup process, and fostering
          flexibility in reporting.
          <br />
          <br />
          Let's take a closer look at the benefits:
        </p>

        <ol>
          <li>
            <p>
              <b>Enhanced data quality</b>
            </p>
            <p>
              In the age of digital marketing, data is king. The ASC
              Specification encourages you to send events into GA4 directly from
              your native code, bypassing clunky tag manager containers. This
              approach ensures greater data integrity and stability, laying a
              solid foundation for accurate analysis. Not to mention - removes
              another third party script from your website.
            </p>
          </li>
          <li>
            <p>
              <b>Broad platform compatibility</b>
            </p>
            <p>
              The ASC Specification is not an isolated effort; it's supported by
              leading automotive website platforms and digital retailing
              software providers, including Driverseat. This wide-ranging
              support guarantees consistency in data collection and analysis,
              regardless of your chosen provider.
            </p>
          </li>
          <li>
            <p>
              <b>Tailored custom events</b>
            </p>
            <p>
              Your dealership is unique, and your analytics should reflect that.
              The ASC Specification allows you to incorporate custom events into
              GA4, showcasing the performance of your products or services.
              Stand out from the competition while maintaining standardized
              data.
            </p>
          </li>
          <li>
            <p>
              <b>Streamlined aggregate reporting</b>
            </p>
            <p>
              For dealership groups juggling multiple website platforms due to
              OEM mandates, the ASC Specification offers a lifeline. With it,
              you can create reliable roll-up reports that enable you to inspect
              and compare online engagement across different tools. It's the key
              to a streamlined, data-driven approach.
            </p>
          </li>
          <li>
            <p>
              <b>Improved Google Ads management</b>
            </p>
            <p>
              Dealerships that embrace the full potential of the ASC spec will
              find themselves in a better position to optimize their Google Ads
              campaigns. The enhanced conversion signals, aligned with
              departmental sales goals, pave the way for a new level of
              precision in campaign management. Say goodbye to sending "mixed"
              conversion signals into Google Ads.
            </p>
          </li>

          <li>
            <p>
              <b>Specific conversion tracking</b>
            </p>
            <p>
              In the era of GA4, precision is the name of the game. The ASC
              Specification supports the measurement of conversions by
              department (sales, service, parts) and conversion outcomes. Say
              goodbye to vague conversion definitions and hello to campaign
              optimization based on outcomes rather than just clicks.
            </p>
          </li>
          <li>
            <p>
              <b>Continual Enhancement</b>
            </p>
            <p>
              The ASC Specification is not a static document. It evolves
              alongside the ever-changing landscape of GA4. Google regularly
              updates GA4, and the ASC Specification is your ally in staying
              ahead of the curve. No more headaches trying to keep up with GA4
              changes independently.
            </p>
          </li>
        </ol>
        <h2>Welcome to the Future of Automotive Analytics</h2>
        <p>
          The ASC Specification for GA4 is a game-changer for automotive
          dealerships. It lays the foundation for standardized, high-quality
          analytics, providing a competitive edge in the digital marketplace.
          <br />
          <br />
          At Driverseat, we are thrilled to offer complete support for the ASC
          Specification, giving your dealership the keys to the future of
          automotive analytics. Join us on this exciting journey and unlock the
          potential of standardized, high-quality analytics.
        </p>
      </>
    ),
  },
  {
    title: 'Upgrading to Google Analytics 4: What Dealers Need to Know',
    slug: 'upgrading-to-google-analytics-4-what-dealers-need-to-know',
    description:
      'Universal Analytics will stop collecting data on July 1, 2023. Make sure your dealership is prepared by switching to GA4 now.',
    date: 'Jan 4, 2023',
    datetime: '2023-01-04',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1672782423/GA4_new_hmxfq8.png',
    readingTime: '7 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1672782423/GA4_new_hmxfq8.png"
          alt="Google Logo on a Building"
          width={1200}
          height={707}
        />
        <p>
          If you’re still using Universal Analytics (GA3) to track and analyze
          your dealership’s website traffic, it's time to start thinking about
          upgrading to Google Analytics 4 (GA4).
        </p>
        <p>
          {' '}
          <Link href="https://support.google.com/analytics/answer/10089681">
            GA4
          </Link>{' '}
          is the next generation of Analytics, and it's designed to collect
          event-based data from both websites and apps. This means that GA4 is
          able to better understand the customer journey and provide more
          comprehensive insights into user behavior, but more on that later.
        </p>

        <h2>What's happening with Universal Analytics</h2>
        <p>GA4 is set to replace Universal Analytics on July 1, 2023.</p>

        <p>
          <b>Until July 1</b>
        </p>
        <ul>
          <li>
            You can continue to use and collect new data in your GA3 properties,{' '}
            <i>but</i>
          </li>
          <li>If you’re still using GA3, think about upgrading to GA4 </li>
        </ul>
        <p>
          <b>After July 1</b>
        </p>
        <ul>
          <li>
            You will be able to access previously processed data in your GA3
            property for at least six months{' '}
            <i>(we will update this post when Google provides a sunset date)</i>{' '}
          </li>
          <li>
            During this time, it's important to export your historical reports,
            as your data will no longer be accessible in the Analytics
            dashboard.{' '}
          </li>
        </ul>
        <p>
          Now, you might be wondering how this all impacts your dealership. In
          short, it's important to make the switch to GA4 as soon as possible to
          ensure that you don't lose access to valuable data and insights.{' '}
        </p>
        <p>
          In this post, we'll cover the benefits of GA4, what the transition
          means for you, and offer some tips on how to make the switch.{' '}
        </p>
        <p>Let's get started!</p>
        <h2>The Benefits of Google Analytics 4 for Dealers</h2>
        <p>
          GA4 brings a{' '}
          <Link href="https://blog.google/products/marketingplatform/analytics/new-way-unify-app-and-website-measurement-google-analytics/?_ga=2.145702130.73583661.1646080530-215962983.1632513725">
            number of improvements over its predecessor.
          </Link>{' '}
          Here are some of the key differences that can make an impact for your
          dealership:
        </p>
        <h3>Uses Event-Based Data Instead of Session-Based Data</h3>
        <p>
          Imagine that a customer visits your dealership website and browses
          several different pages, but doesn't take any further action (such as
          filling out a form). In a <u>session</u>-based tracking system, this
          user's behavior would be recorded as a single session, with no further
          data collected after the session ends.
        </p>
        <p>
          However, with <u>event</u>-based tracking, GA4 would continue to
          collect data on the user's actions as they occur. For example, if the
          user later returns to the website and fills out a form to request more
          information on a specific vehicle, that action would be recorded as an
          event.{' '}
        </p>
        <p>
          This allows you to get a more accurate picture of the user's behavior
          and interests, and can help you make more informed decisions about how
          to engage with them.
        </p>
        <p>
          For a car dealership, this type of event-based tracking can be
          particularly useful for understanding the customer journey and
          identifying opportunities to engage with potential buyers. By tracking
          specific actions (such as form submissions or page views) as events,
          you can get a more detailed view of user behavior and tailor your
          marketing efforts accordingly.
        </p>
        <h3>Enhanced Tracking and Measurement Capabilities</h3>
        <p>
          With GA4, dealers can track user behavior across devices and
          platforms, allowing for a more complete view of the customer journey.
        </p>
        <p>
          For example, let's say that a car shopper starts by browsing your
          inventory on their phone, but then decides to schedule a test drive
          from their laptop. With GA4, you can track this user's behavior across
          devices, and get a more complete picture of their interests and
          intentions.
        </p>
        <p>
          Based on this data, the dealership can then make more informed
          decisions about how to engage with this user.{' '}
        </p>
        <h3>Predictive Capabilities Without Complex Models</h3>
        <p>
          Imagine that you own a car dealership and you want to better
          understand which cars on your website are most likely to generate
          leads. With GA4's predictive capabilities, you can get a fairly good
          idea, without having to build complex models.{' '}
        </p>
        <p>
          For example, GA4 might predict that sedans are more likely to generate
          leads compared to SUVs based on past data. With this information, you
          could choose to feature sedans more prominently on your website and
          allocate more resources to promoting them. This could potentially
          increase the number of leads you receive and help your dealership sell
          more vehicles.
        </p>
        <h3>Improved Integration with Other Google Products</h3>
        <p>
          Great news for dealers that run Google Ads. with GA4, you can easily
          link your Google Ads account to your GA4 property, allowing you to
          analyze the performance of your ads within your Analytics account.
        </p>
        <p>
          For example, you can see which ads are driving the most traffic to
          your website, and which ads are leading to the most conversions (such
          as test drives or lead captures). This can help you identify which ads
          are most effective, and allow you to optimize your ad campaigns to get
          the best return on investment.
        </p>
        <p>
          In addition to Google Ads, GA4 also integrates seamlessly with Google
          BigQuery, which is a cloud-based data warehouse that allows you to
          analyze large datasets. With this integration, you can easily import
          data from your GA4 property into BigQuery for more in-depth analysis.
          This can be particularly useful for car dealerships that have large
          amounts of data and want to gain more insights from it.
        </p>
        <h3>Enhanced Privacy Controls </h3>
        <p>
          With GA4's enhanced data privacy controls, the dealership can choose
          which data they collect and share, and can implement measures such as
          cookieless measurement and behavioral and conversion modeling to
          protect user data. This can help to build trust with website visitors,
          and ensure that the dealership is compliant with relevant data privacy
          regulations.
        </p>
        <p>
          For example, you might choose to only collect and share data that is
          necessary for completing a test drive, and might use behavioral and
          conversion modeling to protect user data while still gaining insights
          into user behavior.{' '}
        </p>
        <p>
          This can help to strike a balance between collecting the data needed
          to provide a great user experience and protecting user privacy.
        </p>
        <h3>Tips for Making the Switch to GA4</h3>
        <p>
          Making the switch to GA4 doesn't have to be a daunting task. Here are
          a few tips to help you get started:
        </p>
        <ul>
          <li>
            <b>Familiarize yourself with GA4:</b> Take some time to learn about
            the features and capabilities of GA4, and how they differ from those
            of Universal Analytics. This will help you understand what's new and
            what you need to know to make the most of the platform.
          </li>
          <li>
            <b>Set up a GA4 property:</b> To start tracking and analyzing data
            with GA4, you'll need to set up a GA4 property. This process is
            fairly straightforward, and Google provides a{' '}
            <Link href="https://support.google.com/analytics/answer/9744165?hl=en#zippy=%2Cin-this-article">
              step-by-step guide.
            </Link>{' '}
            to help you get started.
          </li>
          <li>
            <b>Migrate your data:</b> If you have a lot of data in your
            Universal Analytics property, you may want to consider migrating it
            to GA4. This can help you maintain a consistent view of your data
            over time, and make it easier to compare your performance before and
            after the switch.
          </li>
          <li>
            <b>Test and optimize:</b> Once you've set up your GA4 property and
            migrated your data, it's important to test and optimize your
            tracking and measurement setup. This will help you ensure that
            you're collecting the right data and using the platform to its full
            potential.
          </li>
          <li>
            <b>Keep learning:</b> GA4 is a constantly evolving platform, with
            new features and updates being released regularly. To make the most
            of GA4, it's important to stay up-to-date.
          </li>
        </ul>
        <h2>Driverseat is here to help</h2>
        <p>
          If you have any questions about transitioning your dealership to GA4,
          don’t hesitate to reach out – our team will be more than happy to
          assist you during any stage of the process.{' '}
        </p>
        <p>
          As a reminder, Universal Analytics will stop collecting data on July
          1, 2023. If you're currently using Universal Analytics, it's important
          to start the process of switching to GA4 as soon as possible to ensure
          that you don't lose access to this valuable data.{' '}
        </p>
        <p>
          By taking the time to familiarize yourself with GA4, set up a GA4
          property, migrate your data, and use available resources and support,
          you can make the transition smoothly and reap the benefits of the new
          Analytics.
        </p>
      </>
    ),
  },
  {
    title: 'Reducing Dealer Dependency on Paid Search Engine Marketing',
    slug: 'reducing-dealer-dependency-on-paid-search-engine-marketing',
    description:
      'A better website doesn’t just provide higher engagement and a better experience for car shoppers, it can also save your dealership thousands of dollars every month.',
    date: 'Sept 26, 2022',
    datetime: '2022-09-26',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1664223002/Cover_ozaves.png',
    readingTime: '8 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1664223002/Cover_ozaves.png"
          alt="Google Logo on a Building"
          width={1200}
          height={707}
        />
        <p>
          We can preach about the importance of website performance until the
          end of time. <i>But</i> a better website doesn’t just provide higher
          engagement and a better experience for car shoppers,{' '}
          <u>
            it can also save your dealership thousands of dollars every month
          </u>
          .
        </p>
        <p>
          <b>Here’s how 👇</b>
        </p>

        <h2>Site Speed & Search 🔎</h2>
        <p>
          Google has been using site speed as a{' '}
          <Link href="https://developers.google.com/search/blog/2010/04/using-site-speed-in-web-search-ranking">
            ranking signal
          </Link>
          &nbsp;for desktop searches as far back as 2010. And in 2018, the
          Google Search team has announced that it{' '}
          <Link href="https://developers.google.com/search/blog/2018/01/using-page-speed-in-mobile-search">
            will begin using page speed in mobile search rankings
          </Link>
          , as well.
        </p>

        <p>
          While this is a <u>big win for consumers</u>, it <i>might not be</i>{' '}
          for your dealership. When <b>real</b> users have a slow experience,
          they’re much less likely to find what they’re looking for or to
          purchase from you in the future.
        </p>
        <p>
          For many dealers, their slow site equates to a huge missed
          opportunity, especially when{' '}
          <Link href="https://www.thinkwithgoogle.com/consumer-insights/consumer-trends/mobile-site-load-time-statistics/">
            more than half of all visits are abandoned if a mobile page takes
            over 3 seconds to load.
          </Link>
        </p>

        <h2>
          Don’t rent search visibility from Google,{' '}
          <i>
            <u>own it</u>
          </i>
          &nbsp;✨
        </h2>

        <p>
          At Driverseat, our main objective is to provide the best possible car
          buying experience for your visitors. We know what keeps car shoppers
          engaged, <i>but</i> we also know how to keep Google happy. That’s why
          <b>
            &nbsp;your Driverseat website will out-preform any other website
            provider
          </b>
          &nbsp;right out of the box.{' '}
        </p>

        <p>
          That said, <u>talk is cheap</u> – so we want to share an example and
          provide some quantitative data.{' '}
          <i>
            For illustrative purposes, we have changed the name of the
            dealership in question to protect their privacy – We’ll call them
            <b> “ACME Motors” </b>moving forward.
          </i>{' '}
        </p>

        <p>
          ACME Motors joined Driverseat in July of this year. We took a baseline
          measurement of their Organic Search keyword distribution prior to the
          switch. At the time, ACME Motors was ranking for <b>3,462 keywords</b>
          , with the following distribution:
        </p>

        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1664223108/before_pfdg34.png"
          alt="Before: Keyword Distribution Graph:"
          width={1200}
          height={827}
        />

        <p>
          For some additional context – when ACME Motors switched to Driverseat,
          <i> no additional content was created</i>, and only existing content
          was moved over. That said, within just <u>60 days</u>, ACME Motors was
          now ranking for
          <b> 8,597 keywords</b>.
        </p>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1664223108/after_dnotcz.png"
          alt="After: Keyword Distribution Graph:"
          width={1200}
          height={827}
        />

        <p>
          Aside from ranking for more than <u>twice the number of keywords</u>,
          you can see significant improvements in the ranking positions that
          matter most (<i>1-10th spots – aka the first page of Google</i>).
        </p>

        <p>
          As a result of this organic boost, ACME Motors chose to{' '}
          <u>stop running paid search ads</u> to their new Driverseat website.
        </p>
        <p>
          Here’s the result after <b>just 1 month </b>on the platform ⬇️{' '}
        </p>
        <ul>
          <b>
            A <u>79% increase</u> in organic search sessions 🚀
          </b>
          <img
            className="w-full rounded-lg"
            src="https://res.cloudinary.com/driverseat/image/upload/v1664216781/Frame_282_vymhh6.png"
            alt="Organic Traffic Improvements:"
            width={1200}
            height={827}
          />
        </ul>
        <ul>
          <b>Drastic improvements in Search Performance 💥 </b>
          <li>
            <u>3.37% increase</u> in the number of SERP impressions,
          </li>
          <li>
            <u>35.1% increase</u> in the number of SERP clicks,
          </li>
          <li>
            <u>30.7% increase</u> in SERP CTR, and a{' '}
          </li>
          <li>
            <u>95% increase</u> in the number of sessions originating from SERPs
          </li>
          <img
            className="w-full rounded-lg"
            src="https://res.cloudinary.com/driverseat/image/upload/v1664217000/Frame_282_etcy68.png"
            alt="Search Console metrics"
            width={1200}
            height={827}
          />
        </ul>

        <p>
          As a result, ACME Motors is able to drastically cut down on their paid
          search spend, while getting a lot more organic traffic to their
          website – <i>completely hands off.</i>{' '}
        </p>

        <h2>How do we do it? 🙋‍♀️</h2>
        <p>
          First and foremost, the Driverseat website platform is built on a
          modern technology stack used by industry leaders like Facebook,
          AirBnb, Youtube, and more.
        </p>
        <p>
          This infrastructure has <b>never before been seen in automotive</b>,
          and offers many tangible benefits compared to the traditional
          dealership website implementation. We stand on the shoulders of
          giants, and bring the latest in tech to every car dealership that we
          work with.
        </p>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1664220048/ezgif-3-42505b508e_cjpwk5.gif"
          alt="Comparison of a Driverseat website against a Dealer.com website."
          width={1200}
          height={827}
        />
        <p>
          If you’re interested in taking a deep dive into how our team is able
          to pull this off, then read on... But, if you’d like to experience the
          power of Driverseat for yourself,{' '}
          <Link href="https://driverseat.io/schedule-demo">
            schedule a quick demo
          </Link>
          &nbsp;and we’ll show you how your dealership can <u>save thousands</u>
          &nbsp;every single month, while improving your customers’ car buying
          experience and generating more leads in the process.
        </p>
        <h2>The deep dive 🤿</h2>
        <p>
          Here are just some of the things we do to make sure your dealership
          outperforms your competition, every single time 👇
        </p>
        <ul>
          <li>
            <b>
              Every dealership website powered by Driverseat is{' '}
              <Link href="https://vercel.com/docs/concepts/edge-network/overview">
                <b>served on the edge</b>,
              </Link>
            </b>
            &nbsp;– meaning that your site’s content is always served on servers
            that are physically closer to your visitors. By doing this, we’re
            able to minimize trip distance that data has to travel, which
            improves speeds even on slow or unreliable networks.
          </li>
          <li>
            <b>Every page is</b>{' '}
            <Link href="https://vercel.com/docs/concepts/edge-network/overview">
              <b>statically generated</b>,
            </Link>{' '}
            – meaning that all pages are generated and cached as static files on
            the CDN. Ultimately, this means that your website{' '}
            <i>doesn’t need to make a new request to the server </i>when car
            shoppers visit a page, as is the case with other website providers.{' '}
            <p>
              With Driverseat, every page is pre-loaded and ready to receive a
              visit from your shoppers at all times – drastically improving
              loading times.{' '}
            </p>
          </li>
          <li>
            <b>
              Loading of third party scripts is deferred until the page is
              interactive.{' '}
            </b>
            By doing this, we’re able to improve your visitor’s browsing
            experience without removing functionality of third party vendors
            that help you run your business.{' '}
          </li>
          <li>
            Many various{' '}
            <b>optimizations to keep the JavaScript (JS) sent to a minimum.</b>{' '}
            The amount of JS (in kilobytes) your website sends to visitors
            impacts your site’s web vitals.{' '}
          </li>
          <li>
            Every site is <b>server-side rendered</b> – meaning that your
            website will be ready for visitors before it even reaches the
            browser, resulting in a great boost in speed.{' '}
          </li>
          <li>
            We
            <b>
              &nbsp;reduce the amount of JavaScript computation done in the
              browser.
            </b>
            This drastically improves website performance on low-end devices.
          </li>
          <li>
            <b>Every website uses proper schema</b> (HTML and structured
            content) to make sure that both – users on accessible devices, as
            well as search engine bots can better understand the content on
            every page.{' '}
          </li>
          <li>
            By using <Link href="https://nextjs.org">Next.js,</Link> we can make
            sure that <b>search engine bots will see HTML content</b>. This
            drastically improves technical SEO, since crawlers are able to find
            content in the HTML shipped to the browser.{' '}
          </li>
          <li>
            <b>All images are converted to a next-gen format</b>, to further
            improve performance. Every image on your Driverseat website will be
            served in a WebP format – delivering the same quality as a JPEG
            while drastically reducing image size.{' '}
          </li>
          <li>
            We <b>continuously monitor all of the web vitals</b> for every
            deployment. This allows our team to make sure that we stay on track
            with every update and continue to push our performance.{' '}
          </li>
        </ul>

        <h2>Learn more about the technology that powers Driverseat</h2>
        <p>
          We’re very proud with the results that Driverseat is able to achieve
          for our dealerships, and we want to share the keys to our success when
          it comes to performance and search engine visibility.
        </p>
        <p>
          If you have any more questions about the technology that powers the
          future of car dealerships, you can{' '}
          <Link href="https://cal.com/alexbudure/15min">
            <b>schedule a call with our CTO</b>
          </Link>
          &nbsp;– Alex will be happy to answer any remaining questions that you
          may have.
        </p>
      </>
    ),
  },
  {
    title: 'What Google’s “helpful content update” means for your dealership',
    slug: 'what-googles-helpful-content-update-means-for-your-dealership',
    description:
      "Google's helpful content update is here – learn about the latest algorithm change and how it may impact your dealership search engine rankings.",
    date: 'Aug 22, 2022',
    datetime: '2022-08-22',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1661184919/GoogleHelpfulContentCover.jpg',
    readingTime: '5 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1661184919/GoogleHelpfulContentCover.jpg"
          alt="Google Logo on a Building"
          width={1200}
          height={707}
        />
        <p>
          Here at Driverseat, we place a big focus on delivering the best
          experience to car shoppers – so we were excited to hear that Google is
          rolling out a new search ranking algorithm change that will reward
          this intent.
        </p>
        <p>
          Last week, Google announced what they're calling{' '}
          <b>"the helpful content update,"</b> which will begin rolling out
          today, August 22. The latest algorithm change will focus on
          recognizing and boosting content written for people, while at the same
          time devaluing content written primarily for SEO.
        </p>
        <p>
          The update also introduces a new ranking signal that will negatively
          impact sites (not just pages) that publish high amounts of content
          with no or little-added value.
        </p>
        <p>
          Let’s take a look under the hood, and learn how you can benefit from
          this change at your dealership. Here’s what we know:{' '}
        </p>
        <h2>What is helpful content?</h2>
        <p>
          In short,{' '}
          <Link href="https://developers.google.com/search/blog/2022/08/helpful-content-update">
            Google defines helpful content as follows
          </Link>
          – it’s created for a specific audience, it features expertise, it’s
          trustworthy and credible, and finally – it meets the wants and needs
          of the searcher.
        </p>

        <p>
          Effectively, all 4 of these factors boil down to 1 thing – a big focus
          on <i>people</i>, as opposed to search engines. This new classifier
          process is entirely automated using a machine-learning model, meaning
          that you need to act fast in order to avoid being hit with a penalty.
        </p>

        <h2>How to create helpful content? </h2>
        <p>
          When creating pages or writing blog posts, ask yourself these
          questions to help determine whether your content is helpful or just
          fluff:
        </p>
        <ul>
          <li>
            Do you have an intended audience for your dealership that would find
            the content useful if they came directly to your website?{' '}
          </li>
          <li>
            Are you just throwing out lots and lots of content with the hopes
            that some of it might stick preform well in SERPs?{' '}
          </li>
          <li>Are you relying solely on automation to produce content? </li>
          <li>
            Is your content written by someone who knows the topic well? / Does
            your content demonstrate a depth of knowledge?{' '}
          </li>
          <li>
            Does your content provide substantial value when compared to other
            pages in search results?
          </li>
          <li>
            Are you mainly summarizing what others have to say without adding
            much value?{' '}
          </li>
          <li>
            Do you abuse clickbait? / Does the headline avoid being exaggerating
            or shocking in nature?
          </li>
          <li>
            Do you evaluate your content from the perspective of a potential
            reader? Will someone leave feeling that they’ve learned enough about
            a topic to help achieve their goal?{' '}
          </li>
        </ul>

        <h2>What should you do?</h2>
        <p>
          While this is a massive ranking change, Google did not give much time
          to prepare. Not to worry, there are a few things you can do right now
          to make sure you remain in good standing with Google. It’s important
          to act fast, however, as Google warns that sites impacted by this
          update may continue to suffer the negative consequences up to a few
          months after removing the unhelpful content.{' '}
        </p>
        <p>
          For starters, it’s important to complete a content audit to make sure
          that all of your existing content can be considered helpful by running
          it through the list of questions above. If any of your pages or blog
          posts only exist to please the search engines, it’s best to delete
          them entirely to avoid being penalized.
        </p>
        <p>
          The new ranking signal is weighted – meaning that websites with lots
          of unhelpful content might notice a stronger effect.{' '}
        </p>
        <p>
          Moving forward, it’s imperative to keep the people-focused approach in
          mind, instead of trying to hit arbitrary word counts, or stuff your
          copy with irrelevant keywords. Sometimes, less is more, and in this
          case it’s better to produce fewer content pieces, as long as they
          tackle a topic in greater depth and with a demonstrable level of
          expertise.{' '}
        </p>
        <p>
          The good news is that if you do produce high quality, “helpful
          content,” it is now much more likely to be highlighted in featured
          snippets in search, as well as rank higher organically across all
          local search markets.{' '}
        </p>
        <h2>We can help!</h2>
        <p>
          Driverseat can help your dealership navigate this update in a number
          of ways – starting with better copy. Our copywriters can help you
          generate helpful and unique content tailored specifically for your
          dealership.{' '}
        </p>
        <p>
          Additionally, all dealerships on the Driverseat platform will soon be
          receiving a new report monitoring their website SERP rankings and
          keywords.{' '}
        </p>
        <p>
          If you’re not yet a Driverseat customer,{' '}
          <Link href="/schedule-demo">schedule a no-commitment demo</Link>, and
          we’ll provide you with a free content audit regardless of your current
          website provider.{' '}
        </p>
      </>
    ),
  },
  {
    title: 'Introducing: VIN Explorer',
    slug: 'introducing-vin-explorer',
    description:
      'VIN Explorer is finally here! Try it today and decode any VIN and get the most accurate information on that vehicle from over 2 dozen sources.',
    date: 'June 18, 2022',
    datetime: '2022-06-18',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1660417695/driverseat/cover-feGN9J4VwTRuAZHXYMs6-2.jpg.png',
    readingTime: '8 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <figure>
          <img
            className="w-full rounded-lg"
            src="https://res.cloudinary.com/driverseat/image/upload/v1660417695/driverseat/cover-feGN9J4VwTRuAZHXYMs6-2.jpg.png"
            alt="Abstract art piece"
            width={3020}
            height={1746}
          />
          <figcaption>
            We’re really excited to announce that our VIN Explorer tool is
            finally here, and available to everyone for free while in Early
            Access.
          </figcaption>
        </figure>

        <h2>Summary</h2>
        <p>
          VIN Explorer is allows you to decode any VIN and get the most accurate
          information on that vehicle from over 2 dozen sources including KBB,
          JD Power, Car Gurus, Edmunds, Carfax, AutoCheck, Vroom, Carvana,
          Carmax, and more.
        </p>
        <p>
          Every dealership on the Driverseat platform will notice a new tab on
          the sidebar called “VIN Explorer,” which will let you enter and decode
          any VIN. When a VIN has been decoded you will be able to view the
          vehicle’s original window sticker and see 7 categories of information
          for each vehicle:
        </p>
        <ol>
          <li>
            <b>Pricing</b>
            <img
              className="w-full rounded-lg"
              src="https://res.cloudinary.com/driverseat/image/upload/v1660418047/driverseat/Image.jpg"
              alt="Abstract art piece"
              width={1200}
              height={387}
            />
            <p>
              The pricing section includes the vehicle’s MSRP & estimated retail
              value, as well as more pricing data sourced from KBB, JD Power,
              CarGurus, Edmunds, and third-party marketplaces like Vroom,
              Carvana, and Carmax.
            </p>
            <p>
              This section should help your dealership price trade-ins, and see
              how you could compare to third-party marketplaces.
            </p>
          </li>
          <li>
            <b>History</b>
            <img
              className="w-full rounded-lg"
              src="https://res.cloudinary.com/driverseat/image/upload/v1660418047/driverseat/Image.jpg.png"
              alt="Abstract art piece"
              width={1200}
              height={827}
            />
            <p>
              The history section includes previous (verified) odometer readings
              and current estimates, ownership information, and a selection of
              the most relevant data from Carfax and AutoCheck.
            </p>
            <p>
              In this section you will be able to learn about the number of
              previous owners, accidents, service records, ownership location,
              vehicle use, and more.
            </p>
          </li>
          <li>
            <b>Recalls</b>
            <img
              className="w-full rounded-lg"
              src="https://res.cloudinary.com/driverseat/image/upload/v1660418047/driverseat/Image.jpg-2.png"
              alt="Abstract art piece"
              width={1200}
              height={752}
            />
            <p>
              This section is pretty self-explanatory – if this vehicle has any
              recalls, all of them along with their details will be listed here.
              This data is sourced from NHTSA.
            </p>
          </li>
          <li>
            <b>Basics</b>
            <img
              className="w-full rounded-lg"
              src="https://res.cloudinary.com/driverseat/image/upload/v1660418047/driverseat/Image.jpg-3.png"
              alt="Abstract art piece"
              width={1200}
              height={518}
            />
            <p>
              The “Basics” section includes all of the basic specs for this
              vehicle (down to the Sub Trim, if available).
            </p>
          </li>
          <li>
            <b>Warranty</b>
            <img
              className="w-full rounded-lg"
              src="https://res.cloudinary.com/driverseat/image/upload/v1660418047/driverseat/Image.jpg-4.png"
              alt="Abstract art piece"
              width={1200}
              height={406}
            />
            <p>
              This section provides information on the manufacturer’s original
              warranty.
            </p>
          </li>
          <li>
            <b>Options & Equipment</b>
            <img
              className="w-full rounded-lg"
              src="https://res.cloudinary.com/driverseat/image/upload/v1660418047/driverseat/Image.jpg-5.png"
              alt="Abstract art piece"
              width={1200}
              height={701}
            />
            <p>
              These 2 sections go into much more depth than the “Basics,” –
              displaying all of the options and extras that are configured for
              this vehicle.
            </p>
          </li>
        </ol>
        <p>
          We’re very excited about this launch, and VIN Explorer will remain
          free for every dealership while in Early Access. We hope that it’s
          useful for your dealership, and are eager to learn about how we can
          continue to improve it.
        </p>
        <p>
          If you’re interested in checking our VIN Explorer, along with the rest
          of Driverseat’s offering, you can schedule a quick no-commitment demo.
        </p>
      </>
    ),
  },
  {
    title: 'How to Address an Inventory Shortage',
    slug: 'how-to-address-an-inventory-shortage',
    description:
      'The single most important thing you can do to navigate inventory shortages or production delays is to create a data-driven approach to your customer communication efforts.',
    date: 'March 24, 2022',
    datetime: '2022-03-24',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1657638898/driverseat/tim-meyer-X-3CqrZd6R0-unsplash.jpg',
    readingTime: '3 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1657638898/driverseat/tim-meyer-X-3CqrZd6R0-unsplash.jpg"
          alt="Abstract art piece"
          width={1200}
          height={707}
        />
        <p>
          According to a recent survey, 76% of in-market car shoppers are aware
          of the ongoing chip shortage. While car buyers may be aware of the
          impact this shortage has on the market, they're not necessarily more
          understanding when it comes to limited availability. This might sound
          bleak at first, but in reality, it presents you with a unique
          opportunity to build trust with your customers.
        </p>
        <p>
          Though there’s no complete certainty with regards to when inventory
          levels will return to “normal,” there are a few things your dealership
          can do to minimize the impact that this shortage has on your
          customers’ car buying experience.
        </p>
        <h2>Improve Communication</h2>
        <p>
          The single most important thing you can do to navigate inventory
          shortages or production delays is to create a data-driven approach to
          your customer communication efforts.
        </p>
        <p>
          Though it’s likely that your available inventory is flying off the
          lot, it’s instrumental that your dealership does not stop
          communicating with your customers. With both – inventory availability
          and dealership loyalty at all–time lows, engaging buyers with
          personalized messaging is critical in standing out from the crowd.
          Identify opportunities to engage audiences with on-brand messaging
          that keeps customers informed throughout their entire car buying
          journey.
        </p>
        <p>
          Your sales team is likely following inbound units just as (if not
          more) closely as your customers that are eagerly awaiting for an
          opportunity to buy. And as your dealership acquires more pre-owned
          units via trade or auction, your team needs to have a concrete
          strategy for marketing your newly available inventory.
        </p>
        <p>
          What this means, is that your dealership needs to create a plan
          reflective of your brand promise, while keeping your customers
          informed in how your team is handling the inventory shortage, and
          provide various opportunities to meet your customers’ unique wants and
          needs. It’s imperative that you remain transparent and proactive
          throughout this process.
        </p>

        <h2>Maintain Transparency</h2>
        <p>
          Dealers should consider creating an easily accessible information
          center on their website, where any frequently asked questions are
          answered. For example – is your dealership selling inbound units or
          allowing customers to reserve specific vehicles while they wait?
        </p>
        <p>
          If you are taking reservations, one way to provide more transparency
          on your website is to clearly tag and label your “reserved” and “in
          transit” inventory. Driverseat allows you to do both of these things,
          while also suggesting other available vehicles as an alternative to
          every car shopper.
        </p>

        <p>
          Schedule a 15-min demo, and we’ll show you how your dealership can
          improve your customers’ car buying experience without any additional
          efforts from your team.
        </p>
      </>
    ),
  },
  {
    title: 'Your Second Most Important Asset',
    slug: 'your-second-most-important-asset',
    description: `The importance of setting up a Google Business Profile cannot be understated when it comes to your dealership's online presence.`,
    date: 'Jan 15, 2022',
    datetime: '2022-1-15',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1648143142/5385893_mzw7zn.jpg',
    readingTime: '3 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1648143142/5385893_mzw7zn.jpg"
          alt="Abstract art piece"
          width={1200}
          height={707}
        />
        <p>
          The importance of setting up a Google Business Profile (GBP) cannot be
          understated when it comes to your dealership's online presence. In
          fact, your GBP listing is your second most important digital asset –
          after your website.
        </p>
        <h2>What is a Google Business Profile</h2>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1648145137/Frame_2_ctimpb.png"
          alt="example of GBP"
          width={1048}
          height={180}
        />
        <p>
          Quick refresher for those who aren't familiar with the service, GMP
          (previously Google My Business) is a free service designed to help
          promote local businesses online. This tool enables business owners to
          stand out while providing valuable information to potential customers.{' '}
        </p>
        <p>
          Since nearly every car shopper uses Google at one point or another, it
          means that there's potential for a large audience to view and access
          your dealership profile – all without a need to spend additional
          marketing dollars.
        </p>
        <h3>Benefits of GBP</h3>
        <p>
          According to Search Engine Watch, more than 50% of people who use a
          mobile device for local searches end up visiting a store. This means
          that by setting up a GBP for your showroom and service department,
          you're likely to see an increase in foot traffic without increasing
          your marketing spend.{' '}
        </p>
        <p>
          Here are two of the biggest ways in which a free Google Business
          Profile will elevate your digital presence.{' '}
        </p>
        <h3>
          GBP Allows You To Appear in Maps and Google Local Pack Listings{' '}
        </h3>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1648144771/gmbbb_jd2nmm.png"
          alt="example of the google local pack"
          width={1048}
          height={180}
        />
        <p>
          If you've ever searched for a business on Google, then you've seen
          what is called the Google Local Pack (a selection of businesses in
          your area that match your search query).{' '}
        </p>
        <p>
          This category of search results takes up a large amount of real estate
          on the first page of Google, which means it's likely to be the first
          things that potential customers see after typing in their query.{' '}
        </p>
        <p>
          The Local Pack enables users to see information they need at a glance,
          so it's to your benefit to have a complete profile with images,
          operating hours, and services that your dealership can provide.{' '}
        </p>

        <h3>GBP Allows People to Leave Reviews of Your Dealership </h3>
        <p>
          The power of customer reviews cannot be underestimated - for both
          transparency and reliability, as well as organic search rankings.
          According to a study by Moz, online reviews make up about 10% of how
          search engines rank results.{' '}
        </p>

        <p>
          This is a quick win for every dealership, and if you want your
          showroom to be more visible in search results, GBP makes it easy for
          your customers to leave positive feedback. Positive reviews not only
          improve your SERP placement, but also serve as a free and reliable
          advertisement for your dealership.{' '}
        </p>
        <h3>Don't Wait</h3>
        <p>
          If you still don't have GBP set up for your dealership (and service
          department), take a few minutes to set it up today. Make sure to add
          photos, videos, content, hours of operation, and use as many relevant
          categories as you can (there are more than 30 automotive categories to
          choose from).{' '}
        </p>
        <p>
          Once your listing gets 20 reviews and a couple dozen owner-photos, it
          will begin to appear in search and grow organically – providing your
          dealership with more visibility and website visits.{' '}
        </p>
        <p>
          If you have any questions, or are unsure about how to set it up, feel
          free to <Link href="/schedule-demo">reach out</Link>, and our support
          team will be happy to assist you at no cost.
        </p>
      </>
    ),
  },
  {
    title: 'Website Speed and Why It Matters',
    slug: 'website-speed-and-why-it-matters',
    description:
      'Similarly to how slow service at a restaurant often results in poor Google reviews and fewer future visits, the same is true for the web.',
    date: 'Dec 21, 2021',
    datetime: '2021-12-21',
    imageUrl:
      'https://res.cloudinary.com/driverseat/image/upload/v1647897573/driverseat/10866.jpg',
    readingTime: '3 min',
    author: {
      name: 'Aleks Bahdanovich',
      imageUrl:
        'https://res.cloudinary.com/driverseat/image/upload/v1647897943/driverseat/c6fdpg4bkvnmwbtrlqmi.png',
    },
    html: (
      <>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1647897573/driverseat/10866.jpg"
          alt="Abstract art piece"
          width={1200}
          height={707}
        />
        <p>
          Similarly to how slow service at a restaurant often results in poor
          Google reviews and fewer future visits, the same is true for the web.
          Slow site speed can (and often does) result in poor search engine
          rankings, higher bounce rates, lower overall site traffic, and worst
          of all – a negative user experience.
        </p>
        <p>
          As the internet continues to evolve, users are becoming increasingly
          more impatient – and most of them aren’t willing to wait for more than
          2 seconds for the website to load.
        </p>
        <p>
          If your website isn’t fast, you’re likely missing out on additional
          leads (and sales) for your dealership.
        </p>
        <h2>Why is site speed important?</h2>
        <p>
          You may be wondering whether or not shaving a few seconds off of your
          website’s page load time really has such a major impact on customer
          satisfaction and revenue. That’s a fair question – but market data
          tells us that this is really the case.
        </p>
        <p>
          Many studies have shown that site{' '}
          <a
            href="https://www.cloudflare.com/en-ca/learning/performance/more/website-performance-conversion-rates/"
            target="_blank"
            rel="noreferrer"
          >
            speed has a direct impact on conversion rate
          </a>
          . Here are some of the most relevant findings:
        </p>
        <ol>
          <li>
            For every additional second of load time (between seconds 0-5),
            website conversion rates drop by an average of 4.42% (
            <a
              href="https://www.portent.com/blog/analytics/research-site-speed-hurting-everyones-revenue.htm#:~:text=The%20first%205%20seconds%20of,(between%20seconds%200%2D5)"
              target="_blank"
              rel="noreferrer"
            >
              Portent
            </a>
            )
          </li>
          <li>
            The probability of a bounce increases 32% as page load time goes
            from 1 second to 3 seconds (
            <a
              href="https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/page-load-time-statistics/"
              target="_blank"
              rel="noreferrer"
            >
              Think with Google
            </a>
            )
          </li>
          <li>
            The average mobile page takes 15.3 seconds to load (
            <a
              href="https://www.thinkwithgoogle.com/consumer-insights/consumer-trends/mobile-shopping-ecosystem-page-load-speed/"
              target="_blank"
              rel="noreferrer"
            >
              Think with Google
            </a>
            ), while 47% of customer expect it to load in 2 seconds or less
          </li>
          <li>
            Almost 70% of consumers admit that page speed impacts their
            willingness to buy from an online retailer (
            <a
              href="https://unbounce.com/page-speed-report/"
              target="_blank"
              rel="noreferrer"
            >
              Unbounce
            </a>
            )
          </li>
          <li>
            The highest online conversion rates occur on pages with load times
            between 0-2 seconds (
            <a
              href="https://www.portent.com/blog/analytics/research-site-speed-hurting-everyones-revenue.htm#:~:text=The%20first%205%20seconds%20of,(between%20seconds%200%2D5)"
              target="_blank"
              rel="noreferrer"
            >
              Portent
            </a>
            )
          </li>
        </ol>
        <p>
          Aside from impacting your website’s current conversion rate, poor
          performance also greatly impacts future conversions by hampering your
          organic search engine rankings.
        </p>
        <p>
          In the summer of 2018, Google started looking at{' '}
          <a
            href="https://developers.google.com/web/updates/2018/07/search-ads-speed"
            target="_blank"
            rel="noreferrer"
          >
            mobile site speed as a ranking factor
          </a>
          . Today, 3 of the top 4 SEO UX signals are page speed dependent. These
          include time on site, pages per session, and bounce rate.
        </p>
        <p>
          Similar criteria are also used in Google paid search factors, meaning
          that your Adwords efforts will suffer because of slow speed. A poor
          performing website will need to spend much more to place higher,
          resulting in a lower ROI.
        </p>
        <h2>The solution</h2>
        <p>
          At Driverseat, we understand the importance of website performance,
          which is why our dealership websites are built for speed.
        </p>
        <img
          className="w-full rounded-lg"
          src="https://res.cloudinary.com/driverseat/image/upload/v1647897669/driverseat/Screen_Shot_2021-06-30_at_2.46.11_PM.png"
          alt="driverseat GTmetrix results"
          width={1048}
          height={180}
        />
        <p>
          If you’re ready to tackle site speed and increase your website’s
          conversion rate, we’ve got you covered.{' '}
          <Link href="/schedule-demo">Schedule a demo</Link>, and we’ll show you
          why Driverseat is the platform for the next generation of car
          dealerships.
        </p>
      </>
    ),
  },
]

const BlogPage = () => {
  return (
    <Layout title="Blog | Driverseat" description="Follow our latest articles">
      <Navbar2 />
      <div className="relative bg-gray-50 px-4 pb-20 pt-16 sm:px-6 lg:px-8 lg:pb-28 lg:pt-24">
        <div className="absolute inset-0">
          <div className="h-1/3 bg-white sm:h-2/3" />
        </div>
        <div className="relative mx-auto max-w-7xl">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              From the blog
            </h2>
            <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
              Keep up with the latest news from the Driverseat team
            </p>
          </div>
          <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <div
                key={post.title}
                className="flex flex-col overflow-hidden rounded-lg shadow-lg"
              >
                <div className="shrink-0">
                  <img
                    className="h-48 w-full object-cover"
                    src={post.imageUrl}
                    alt={post.title}
                  />
                </div>
                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                  <div className="flex-1">
                    <Link href={`/blog/${post.slug}`} className="mt-2 block">
                      <p className="text-xl font-semibold text-gray-900">
                        {post.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {post.description}
                      </p>
                    </Link>
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="shrink-0">
                      <span className="sr-only">{post.author.name}</span>
                      <img
                        className="size-10 rounded-full"
                        src={post.author.imageUrl}
                        alt={post.author.name}
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {post.author.name}
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime={post.datetime}>{post.date}</time>
                        <span aria-hidden="true">&middot;</span>
                        <span>{post.readingTime} read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage
